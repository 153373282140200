var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dialog-base',{attrs:{"type":"ADD","title":_vm.titleAction,"max-width":"750"},on:{"canceled":_vm.formDialogClose,"confirmed":_vm.validateAndSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"actionForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('currencies-field',{attrs:{"label":_vm.$t('attributes.from'),"readonly":""},model:{value:(_vm.account.from_currency),callback:function ($$v) {_vm.$set(_vm.account, "from_currency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"account.from_currency"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.balance'),"readonly":""},model:{value:(_vm.account.from_balance),callback:function ($$v) {_vm.$set(_vm.account, "from_balance", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"account.from_balance"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"value":_vm.account.from_balance - _vm.itemAction.amount,"label":_vm.$t('attributes.estimated_balance'),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.amount'),"error-messages":errors,"type":"number"},model:{value:(_vm.itemAction.amount),callback:function ($$v) {_vm.$set(_vm.itemAction, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemAction.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.to')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currencies-field',{attrs:{"outlined":"","label":_vm.$t('attributes.to'),"error-messages":errors,"exclude":_vm.account.from_currency},model:{value:(_vm.itemAction.to_currency),callback:function ($$v) {_vm.$set(_vm.itemAction, "to_currency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemAction.to_currency"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.price'),"error-messages":errors,"type":"number"},model:{value:(_vm.itemAction.price),callback:function ($$v) {_vm.$set(_vm.itemAction, "price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemAction.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.fee')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.fee') + '%',"error-messages":errors,"counter":"","type":"number","outlined":"","readonly":""},model:{value:(_vm.account.fee),callback:function ($$v) {_vm.$set(_vm.account, "fee", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"account.fee"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.net_price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.netPrice,"outlined":"","label":_vm.$t('attributes.net_price'),"error-messages":errors,"type":"number"}})]}}],null,true)})],1)],1),_c('button',{staticClass:"hide",attrs:{"type":"submit"}})],1)]}}])})]},proxy:true}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }