<template>
    <div style="height: calc(100vh - 90px);" class="ma-n3">
        <ChatBox :contact="currentContact" @changeChat="changeChat" />
        <ListUsers @contact-updated="selectContact" ref="listUsers" />
    </div>
</template>

<script>
import ListUsers from "./ListUsers.vue";
import ChatBox from "./ChatBox.vue";

export default {
    components: { ListUsers, ChatBox },

    props: {},

    data: function() {
        return {
            currentContact: {}
        };
    },
    computed: {},
    created() {},
    methods: {
        selectContact(contact) {
            this.currentContact = contact;
        },

        /**
         *  We use this method When user want to go to private chat with contact from general group
         * @param {object} contact
         */
        changeChat(contact) {
            this.$refs.listUsers.changeChat(contact);
        }
    }
};
</script>
