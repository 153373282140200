<template>
    <!-- Start Chareg/withdrawal form dialog -->
    <dialog-base
        type="DELETE"
        confirmLabel="إلغاء البيع"
        :title="titleAction"
        v-model="formDialog"
        @canceled="formDialogClose"
        @confirmed="validateAndSave"
        max-width="450"
    >
        <template #content>
            <validation-observer ref="actionForm" v-slot="{ handleSubmit }">
                <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                    <v-alert type="info">
                        لا تستطيع عرض الطلب مجددا بعد إلغائه
                    </v-alert>

                    <!-- show errors if there is errors when add/update item -->
                    <has-errors :errors="errors" />

                    <p style="font-size:1.15rem; line-height: 1.5rem;" class="pa-2 mb-0">
                        هل أنت متأكد أنك تريد إلغاء بيع
                        <bdi class="font-weight-bold">{{ item.amount }} {{ item.seller.currency.name }}</bdi>
                        بقيمة
                        <bdi class="font-weight-bold">{{ item.price }} {{ item.receiver.currency.name }}</bdi>
                        ؟
                    </p>

                    <v-row>
                        <v-col cols="12">
                            <validation-provider rules="max:1000" :name="$t('attributes.reason')" v-slot="{ errors }">
                                <v-textarea
                                    outlined
                                    v-model.trim="itemData.reason"
                                    :label="$t('attributes.reason')"
                                    :error-messages="errors"
                                    counter
                                    :maxlength="500"
                                    rows="3"
                                    auto-grow
                                    clearable
                                ></v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>

                    <!-- This button to allow to user to submit form by clicking enter -->
                    <button type="submit" class="hide" />
                </v-form>
            </validation-observer>
        </template>
    </dialog-base>
    <!-- End Chareg/withdrawal form dialog -->
</template>

<script>
import axios from "axios";
export default {
    props: {
        item: { type: Object, required: true }
    },

    data: function() {
        return {
            errors: {},
            itemData: {}
        };
    },

    computed: {
        titleAction() {
            return "إلغاء بيع عملة";
        },
        formDialog() {
            return this.item.formCancelShow;
        }
    },

    methods: {
        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.actionForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            this.$store.state.app.loading = true;

            axios
                .post(`sell-offers/cancel/${this.item.id}`, this.itemData)
                .then(r => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$emit("canceled-item", r.data.data); // triger event on item bought successful

                    //this.$router.push("accounts"); // redirect to accounts page
                })
                .catch(e => {
                    window.eeee = e;
                    console.log(e);
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else if (e.response.data.message) {
                        this.errors = [e.response.data.message];
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.item.formCancelShow = false;
            this.errors = {};
            this.$refs.actionForm.reset();
            this.item = {};
            this.itemData = {};
        }
    }
};
</script>
