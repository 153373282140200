<template>
    <v-card :loading="loading" flat class="d-flex flex-column">
        <v-card-title>
            <v-icon class="me-2" @click="$store.state.chat.showListUsers = !$store.state.chat.showListUsers">
                mdi-menu
            </v-icon>
            {{ contact.name }}
        </v-card-title>

        <template slot="progress">
            <v-progress-linear color="info" height="8" indeterminate></v-progress-linear>
        </template>

        <v-card-text ref="boxMsgs" class="my-msg-box flex-grow-1 overflow-y-auto">
            <template v-if="contactExists">
                <template v-for="m in messages">
                    <div
                        :class="'my-msg ' + ((m.sender.id == 1 && 'green') || (auth.id == m.sender.id ? 'ms-auto info' : 'me-auto'))"
                        :key="'msg' + m.id"
                    >
                        <span class="my-msg-sender">
                            <bdi>{{ m.sender.name }}</bdi>
                            <v-btn
                                icon
                                class="mx-2"
                                x-small
                                v-if="m.group && m.sender.id != 1 && auth.id != m.sender.id"
                                @click="chatWithContact(m.sender)"
                            >
                                <v-icon>mdi-account-plus</v-icon>
                            </v-btn>
                        </span>
                        <div class="my-msg-content">{{ m.body }}</div>
                        <bdi class="my-msg-time ms-auto date-to-since" :title="m.created_at">{{ m.created_at }}</bdi>
                    </div>
                </template>
            </template>
            <h2 v-else class="ma-auto text-h4">اختر محادثة</h2>
        </v-card-text>

        <v-card-text class="flex-shrink-1">
            <v-textarea
                placeholder="اكتب ..."
                class="my-msg-input pt-0 mt-0"
                no-details
                append-icon="mdi-send"
                @keydown.enter.exact.prevent="newMessage"
                @click:append="newMessage"
                hide-details
                rows="2"
                auto-grow
                v-model.trim="msg.body"
                loader-height="5"
                :loading="loadingInput"
                :readonly="loadingInput"
                :disabled="!auth.chat_active || !contactExists"
                v-if="auth.chat_active"
            />
            <h3 v-else class="py-3 red--text text--darken-1">يرجى التواصل مع الادمن لتفعيل إرسال الرسائل</h3>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    props: {
        contact: { type: [Object], required: true }
    },

    data: function() {
        return {
            messages: [],
            msg: {},
            interval: null,
            loading: false,
            loadingInput: false
        };
    },
    computed: {
        ...mapState("auth", {
            auth: state => state.user
        }),

        contactExists() {
            return this.contact && this.contact.id;
        }
    },
    created() {},

    mounted() {
        this.interval = setInterval(this.updateDateTimeToSince, 5000);
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    watch: {
        async contact(nval, oval) {
            if (nval.id != oval.id) {
                this.messages = [];
                this.$store.state.app.loading = true;
                this.disconnect(oval.id);
            }
            await this.getMessages();
            this.connect();
            this.markSeen();
            this.$store.state.app.loading = false;
            this.focusOnLastMsg();
        }
    },
    methods: {
        /**
         * connect laravel echo (pusher)
         */
        connect() {
            let channelName;
            if (this.contact.id == -1) {
                channelName = "general"; // general group
            } else {
                channelName = `${this.contact.id}.${this.auth.id}`; // specific chat
            }
            let channel = window.Echo.private(`chat.${channelName}`);
            channel.listen(".chat.message.new", e => {
                let _msg = e;
                //_msg.sender = Object.assign(this.contact);
                _msg.receiver = Object.assign(this.auth);
                this.appendMessage(_msg);
                this.markSeen();
                this.updateDateTimeToSince();
            });
        },
        /**
         * disconnect laravel echo (pusher)
         * @param {Number} contactId
         */
        disconnect(contactId) {
            let channelName;
            if (contactId == -1) {
                channelName = "general"; // general group
            } else {
                channelName = `${contactId}.${this.auth.id}`;
            }
            window.Echo.leave(`chat.${channelName}`);
        },

        async getMessages() {
            this.loading = true;
            await axios
                .get(`chat/messages/${this.contact.id}`)
                .then(r => {
                    this.messages.unshift(...r.data);
                })
                .finally(() => {
                    this.updateDateTimeToSince();
                    this.loading = false;
                });
        },

        async newMessage() {
            if (this.loadingInput || !(this.msg.body && this.msg.body.trim())) return;

            this.loadingInput = true;
            await axios
                .post(`chat/messages/${this.contact.id}`, this.msg)
                .then(r => {
                    let _msg = r.data;
                    _msg.receiver = Object.assign(this.contact);
                    _msg.sender = Object.assign(this.auth);
                    this.appendMessage(_msg);
                    this.msg = {};
                })
                .finally(() => {
                    this.loadingInput = false;
                });
            this.updateDateTimeToSince();
            this.focusOnLastMsg();
        },

        async markSeen() {
            // mark messages as seen when contact is not public group , also have unread messages
            if (this.contact.id < 1 || this.messages.findIndex(e => e.to == this.auth.id && !e.seen_at) == -1) return;
            await axios.post(`chat/messages/${this.contact.id}/mark-seen`).then(() => {
                this.contact.unread = 0;
            });
        },

        /**
         * Append message to loadded messages
         * @param {object} msg
         */
        appendMessage(msg) {
            return this.messages.unshift(msg);
        },

        updateDateTimeToSince() {
            const coll = document.getElementsByClassName("date-to-since");
            for (let i = 0; i < coll.length; i++) {
                coll[i].textContent = moment(coll[i].getAttribute("title")).fromNow();
            }
        },
        focusOnLastMsg() {
            this.$refs.boxMsgs.scrollTop = this.$refs.boxMsgs.scrollHeight + 120;
        },

        /**
         *  We use this method When user want to go to private chat with contact from general group
         * @param {object} contact
         */
        chatWithContact(contact) {
            this.$emit("changeChat", contact);
        }
    }
};
</script>
<style lang="scss">
.my-msg-box {
    display: flex;
    flex-direction: column-reverse;
    background-color: #f0f0f0;
    height: calc(100vh - 210px);
    .my-msg {
        background-color: #fff;
        border-radius: 10px;
        padding: 7px 12px;
        width: fit-content;
        min-width: 100px;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        margin-bottom: 15px;
        .my-msg-sender {
            font-weight: bold;
            font-size: 12px;
        }
        &:not(.info):not(.green) .my-msg-content {
            color: #222;
        }
        .my-msg-content {
            white-space: pre-wrap;
            padding: 3px;
        }

        &.info,
        &.green {
            .my-msg-content {
                color: #fff;
            }
            .my-msg-sender {
                color: #fffc;
            }
            .my-msg-time {
                color: #fffc;
            }
        }
        .my-msg-time {
            font-size: 11px;
        }
    }
    .my-msg-input textarea {
        max-height: 60px;
        overflow-y: hidden;
        font-size: 14px;
    }
}
</style>
