import i18n from "@/plugins/i18n.js";

/**
 * This module for app settings
 */
export default {
    namespaced: true,

    state: {
        layouts: {
            dashboard: {
                sidebar: {
                    show: true
                }
            }
        },

        color: "info",
        dark: false,
        loading: true,
        rtl: true
    },

    getters: {
        links: (state, _getters, _rootState, rootGetters) => {
            /* Start filter links depends on permissions */
            let links = [
                { label: i18n.t("home.title"), icon: i18n.t("home.icon"), url: "/home" },
                { label: i18n.t("chat.title"), icon: i18n.t("chat.icon"), url: "/chat", permissions: ["chat.index"] },
                { label: i18n.t("transactions.title"), icon: i18n.t("transactions.icon"), url: "/transactions", permissions: ["transactions.index"] },
                /* { label: i18n.t("transfers.title"), icon: i18n.t("transfers.icon"), url: "/transfers", permissions: ["transfers.index"] }, */
                { label: i18n.t("sell_offers.title"), icon: i18n.t("sell_offers.icon"), url: "/sell-offers" },
                { label: i18n.t("sell_offers.my.title"), icon: i18n.t("sell_offers.my.icon"), url: "/sell-offers-my" },
                { label: i18n.t("sell_offers.purchases.title"), icon: i18n.t("sell_offers.purchases.icon"), url: "/sell-offers-purchases" },
                { label: i18n.t("clients.accounts.title"), icon: i18n.t("clients.accounts.icon"), url: "/accounts", permissions: ["accounts.index"] },
                { label: i18n.t("profile.title"), icon: i18n.t("profile.icon"), url: "/profile" }
            ];

            let items = links.filter(link => {
                if (link.group) {
                    link.links = link.links.filter(sublink => !sublink.permissions || _hasPermissions(sublink.permissions));
                }

                if (!link.permissions) {
                    return true;
                }

                return _hasPermissions(link.permissions);
            });
            /* End filter links depends on permissions */

            /* remove empty links groups from links , then return links */
            return items.filter(i => !i.group || (i.links && i.links.length));
        }
    },

    actions: {
        changeLanguage({ state }, lang) {
            state.lang = this.$app.$i18n.changeLanguageTo(lang);
            state.rtl = this.$app.$i18n.t("direction") === "rtl";
            this.$app.$vuetify.rtl = state.rtl;
        }
    }
};
