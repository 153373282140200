<template>
    <div>
        <base-crud :icon="$t('clients.accounts.icon')" :title="$t('clients.accounts.title')" stateModule="walletAccount" :headers="headers">
            <!-- datatable actions -->
            <template #itemActions="{ item }">
                <v-btn x-small fab color="blue-grey" class="ms-1" dark @click="prepareToSell(item)">
                    <v-icon>mdi-cached</v-icon>
                </v-btn>
                <v-btn x-small fab class="ms-1" dark @click="prepareToTransfer(item)">
                    <v-icon>{{ $t("transfers.icon") }}</v-icon>
                </v-btn>
            </template>
            <!--/ datatable actions -->
        </base-crud>

        <sell :account="itemAction" @added-item="loadAccounts()" />

        <transfer :account="itemAction" @added-item="loadAccounts()" />
    </div>
</template>

<script>
import store from "@/store";
import Sell from "./Sell.vue";
import Transfer from "./Transfer.vue";

async function loadAccounts() {
    store.state.app.loading = true;

    await store.dispatch(`walletAccount/clearAll`);
    return store.dispatch(`walletAccount/fetchAll`).finally(() => {
        store.state.app.loading = false;
    });
}

export default {
    components: { Sell, Transfer },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        Promise.all([store.dispatch(`setting/fetch`), loadAccounts()]).then(() => next());
    },

    data: function() {
        return {
            formDialog: false,
            errors: {},
            headers: [
                {
                    text: this.$t("attributes.account_number"),
                    value: "id"
                },
                {
                    text: this.$t("attributes.currency"),
                    value: "currency.name"
                },
                {
                    text: this.$t("attributes.balance"),
                    value: "balance"
                },
                {
                    text: this.$t("attributes.note"),
                    value: "note"
                },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ],
            itemAction: {}
        };
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        /**
         *  Set configuration for update item
         */
        prepareToSell(item) {
            this.itemAction = {
                formSellShow: true,
                from_account: item.id,
                from_currency: item.currency_id,
                from_balance: item.balance,
                fee: this.$store.state.setting.item.sell_offer_sell_fee
            };
        },
        /**
         *  Set configuration for update item
         */
        prepareToTransfer(item) {
            this.itemAction = {
                formTransferShow: true,
                from_account: item.id,
                from_currency: item.currency_id,
                from_balance: item.balance,
                fee: this.$store.state.setting.item.transfer_sender_fee
            };
        },

        loadAccounts() {
            loadAccounts();
        }
    }
};
</script>
