import Echo from "laravel-echo";
import Pusher from "pusher-js";
import store from "@/store/index";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: "pusher",
    key: window.__echo_key || "58f497e4665bfbc0c08e",
    cluster: "eu",
    forceTLS: true,
    authorizer: (channel, options) => {
        console.log(options);
        return {
            authorize: (socketId, callback) => {
                axios({
                    method: "POST",
                    url: serverUrl("/broadcasting/auth"),
                    headers: {
                        Authorization: `Bearer ${store.state.auth.token}`
                    },
                    data: {
                        socket_id: socketId,
                        channel_name: channel.name
                    }
                })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    }
});
