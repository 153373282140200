<template>
    <!-- Start Chareg/withdrawal form dialog -->
    <dialog-base type="ADD" :title="titleAction" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave" max-width="750">
        <template #content>
            <validation-observer ref="actionForm" v-slot="{ handleSubmit }">
                <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                    <!-- show errors if there is errors when add/update item -->
                    <has-errors :errors="errors"></has-errors>

                    <v-row>
                        <v-col cols="12" sm="6">
                            <currencies-field v-model.trim="account.from_currency" :label="$t('attributes.from')" readonly />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model.trim="account.from_balance" :label="$t('attributes.balance')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="account.from_balance - itemAction.amount"
                                :label="$t('attributes.estimated_balance')"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field :value="account.fee" :label="$t('attributes.fee')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <validation-provider rules="required|min_value:1" :name="$t('attributes.amount')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="itemAction.amount"
                                    outlined
                                    :label="$t('attributes.amount')"
                                    :error-messages="errors"
                                    type="number"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="itemAction.amount - account.fee || '-'"
                                :label="$t('attributes.net_amount')"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <validation-provider rules="required" :name="$t('attributes.account_number')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="itemAction.to_account"
                                    outlined
                                    :label="$t('attributes.account_number')"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <validation-provider rules="required" :name="$t('attributes.phone')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="itemAction.phone"
                                    outlined
                                    :label="$t('attributes.phone')"
                                    :error-messages="errors"
                                    type="number"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <validation-provider rules="required" :name="$t('attributes.fee')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="itemAction.fee"
                                    outlined
                                    :label="$t('attributes.fee')"
                                    :error-messages="errors"
                                    type="number"
                                ></v-text-field>
                            </validation-provider>
                        </v-col> -->
                        <!-- <v-col cols="12">
                            <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                                <v-textarea
                                    outlined
                                    v-model.trim="itemAction.note"
                                    :label="$t('attributes.note')"
                                    :error-messages="errors"
                                    counter
                                    :maxlength="500"
                                    rows="3"
                                    auto-grow
                                    clearable
                                ></v-textarea>
                            </validation-provider>
                        </v-col> -->
                    </v-row>

                    <!-- This button to allow to user to submit form by clicking enter -->
                    <button type="submit" class="hide" />
                </v-form>
            </validation-observer>
        </template>
    </dialog-base>
    <!-- End Chareg/withdrawal form dialog -->
</template>

<script>
export default {
    props: {
        account: { type: Object, required: true }
    },

    data: function() {
        return {
            errors: {},
            itemAction: {}
        };
    },

    computed: {
        titleAction() {
            return "تحويل لحساب أخر";
        },
        formDialog() {
            return this.account.formTransferShow;
        }
    },

    methods: {
        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.actionForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            let data = Object.assign(this.itemAction);
            data.from_account = this.account.from_account;
            data.fee = this.account.fee;

            this.$store.state.app.loading = true;

            this.$store
                .dispatch("transfer/add", data)
                .then(r => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$emit("added-item", r.data); // triger event on item added or updated item
                })
                .catch(e => {
                    window.eeee = e;
                    console.log(e);
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.account.formTransferShow = false;
            this.errors = {};
            this.$refs.actionForm.reset();
            this.itemAction = {};
        }
    }
};
</script>
