<template>
    <v-navigation-drawer v-model="$store.state.chat.showListUsers" app class="list-users-sidebar" width="300">
        <v-card>
            <v-card-title class="justify-center">
                المحادثات
            </v-card-title>
            <v-app-bar flat color="rgba(0,0,0,0)">
                <v-text-field filled label="بحث ..." clearable append-icon="mdi-magnify" v-model="search" @input="filterContacts"></v-text-field>
            </v-app-bar>

            <v-list two-line class="chat-listusers">
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item v-for="c in contactsFilterd" :key="'contacts_' + c.id" @click="select(c)">
                        <v-list-item-avatar>
                            <v-icon class="contact-online" dark>
                                mdi-account
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="c.name"></v-list-item-title>
                            <v-list-item-subtitle v-if="c.id != -1" v-html="getOnlineStatus(c)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar v-if="c.unread">
                            <v-chip color="success">{{ c.unread }}</v-chip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
    props: {},

    data: function() {
        return {
            contacts: [],
            contactsFilterd: [],
            search: null,
            selectedItem: null,
            intervalContacts: null
        };
    },

    computed: {},

    created() {
        this.$store.state.app.loading = true;

        this.featchContacts()
            .then(() => {
                let index = 0;
                if (this.$route.query.id) {
                    index = this.contacts.findIndex(c => c.id == this.$route.query.id);
                }
                this.selectedItem = index;
                this.select(this.contacts[index]);
            })
            .finally(() => {
                this.$store.state.app.loading = false;
            });
    },

    mounted() {
        this.intervalContacts = setInterval(this.featchContacts, 30000);
    },

    beforeDestroy() {
        clearInterval(this.intervalContacts);
    },

    methods: {
        featchContacts() {
            return axios.get("chat/contacts").then(r => {
                this.contacts = r.data;
                this.sortContacts();
                this.contactsFilterd = this.contacts;
            });
        },

        sortContacts() {
            this.contacts = this.contacts.sort((a, b) => {
                if (a.id == -1) return 0;
                if (a.last_login > b.last_login) return -1;
                if (a.last_login < b.last_login) return 1;
                return 0;
            });
        },

        filterContacts() {
            this.contactsFilterd = this.contacts.filter(e => e.id == -1 || filterContains(e.name, this.search));
        },

        select(contact) {
            this.$emit("contact-updated", contact);
            this.$router.push({ query: { ...this.$route.query, id: contact.id } }).catch(() => {});
        },

        /**
         *  We use this method When user want to go to private chat with contact from general group
         * @param {object} contact
         */
        changeChat(contact) {
            let index = this.contacts.findIndex(e => e.id == contact.id);
            if (index == -1) {
                // first time chatting with contact
                this.contacts.push(contact);
                this.contactsFilterd = this.contacts;
                index = this.contacts.length - 1; // because list ui read it as last index
            }
            this.selectedItem = index;
            this.select(this.contacts[index]);
        },

        getOnlineStatus(contact) {
            if (!contact.last_login) return "غير متصل";

            let last_login = moment(contact.last_login);
            console.log(moment.duration(moment().diff(last_login)).asSeconds());
            if (moment.duration(moment().diff(last_login)).asSeconds() > 58) {
                return last_login.fromNow();
            }

            return `<span style="color: #43a047;">متصل</span>`;
        }
    }
};
</script>

<style lang="scss">
.chat-listusers {
    padding-bottom: 0;
    .v-item-group {
        overflow-y: auto;
        height: calc(100vh - 136px);
    }
}
.contact-online {
    background-color: #4296f4;
    /* background-color: #43a047; */
}
.contact-offline {
    background-color: #ef5350;
}
</style>
