<template>
    <div>
        <base-card class="mt-5 mb-10 mx-auto" icon="mdi-brightness-percent">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">العمولات</div>
                </div>
            </template>

            <template #content>
                <v-row class="px-5 pb-5">
                    <v-col sm="6">
                        <h4>
                            عمولة البيع : <bdi>%{{ $store.state.setting.item.sell_offer_sell_fee }}</bdi>
                        </h4>
                    </v-col>
                    <v-col sm="6">
                        <h4>
                            عمولة الشراء : <bdi>%{{ $store.state.setting.item.sell_offer_buy_fee }}</bdi>
                        </h4>
                    </v-col>
                    <v-col sm="6">
                        <h4>
                            عمولة تحويل : <bdi>{{ $store.state.setting.item.transfer_sender_fee }}</bdi> بالعملة المحولة
                        </h4>
                    </v-col>
                    <v-col sm="6">
                        <h4>
                            عمولة استلام : <bdi>{{ $store.state.setting.item.transfer_receiver_fee }}</bdi> بالعملة المستلمة
                        </h4>
                    </v-col>
                </v-row>
            </template>
        </base-card>

        <base-crud :icon="$t('exchange_rates.icon')" :title="$t('exchange_rates.title')" stateModule="exchangeRate" :headers="headers">
            <!--  <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <currencies-field v-model.trim="search.from" :label="$t('attributes.from')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <currencies-field v-model.trim="search.to" :label="$t('attributes.to')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.sell_amount" :label="$t('attributes.sell_amount')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.buy_amount" :label="$t('attributes.buy_amount')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.note" :label="$t('attributes.note')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.sell_state" :label="$t('attributes.sell_state')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.buy_state" :label="$t('attributes.buy_state')" clearable />
                </v-col>
            </v-row>
        </template> -->
        </base-crud>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            search: {},
            headers: [
                {
                    text: this.$t("attributes.from"),
                    value: "from.name",
                    filter: (value, search, item) => {
                        return _dtFilterEqual(item.from_currency, this.search.from);
                    }
                },
                {
                    text: this.$t("attributes.to"),
                    value: "to.name",
                    filter: (value, search, item) => {
                        return _dtFilterEqual(item.to_currency, this.search.to);
                    }
                },
                {
                    text: this.$t("attributes.sell_amount") + "(/)",
                    value: "sell",
                    //value: "sell_amount",
                    filter: value => filterContains(value, this.search.sell_amount)
                },
                {
                    text: this.$t("attributes.buy_amount") + "(x)",
                    value: "buy",
                    //value: "buy_amount",
                    filter: value => filterContains(value, this.search.buy_amount)
                }
            ],
            intervalRates: null
        };
    },

    created() {
        this.$store.dispatch("setting/fetch");
    },

    mounted() {
        this.intervalRates = setInterval(() => {
            this.$store.dispatch(`exchangeRate/forceFetchAll`);
            this.$store.dispatch("setting/fetch");
        }, 120000); // 2 minutes
    },

    beforeDestroy() {
        clearInterval(this.intervalContacts);
    },

    methods: {}
};
</script>
