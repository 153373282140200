var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dialog-base',{attrs:{"type":"ADD","title":_vm.titleAction,"max-width":"750"},on:{"canceled":_vm.formDialogClose,"confirmed":_vm.validateAndSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"actionForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('currencies-field',{attrs:{"label":_vm.$t('attributes.from'),"readonly":""},model:{value:(_vm.account.from_currency),callback:function ($$v) {_vm.$set(_vm.account, "from_currency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"account.from_currency"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.balance'),"readonly":""},model:{value:(_vm.account.from_balance),callback:function ($$v) {_vm.$set(_vm.account, "from_balance", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"account.from_balance"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"value":_vm.account.from_balance - _vm.itemAction.amount,"label":_vm.$t('attributes.estimated_balance'),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"value":_vm.account.fee,"label":_vm.$t('attributes.fee'),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min_value:1","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.amount'),"error-messages":errors,"type":"number"},model:{value:(_vm.itemAction.amount),callback:function ($$v) {_vm.$set(_vm.itemAction, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemAction.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"value":_vm.itemAction.amount - _vm.account.fee || '-',"label":_vm.$t('attributes.net_amount'),"outlined":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.account_number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.account_number'),"error-messages":errors},model:{value:(_vm.itemAction.to_account),callback:function ($$v) {_vm.$set(_vm.itemAction, "to_account", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemAction.to_account"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.phone'),"error-messages":errors,"type":"number"},model:{value:(_vm.itemAction.phone),callback:function ($$v) {_vm.$set(_vm.itemAction, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemAction.phone"}})]}}],null,true)})],1)],1),_c('button',{staticClass:"hide",attrs:{"type":"submit"}})],1)]}}])})]},proxy:true}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }