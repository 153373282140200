export default class BaseStoreAdvancedModule {
    constructor(serviceApi, namespaced = true) {
        this.namespaced = namespaced;

        this.state = {
            items: [],
            item: []
        };

        this.getters = {
            getById: state => id => {
                return state.items.data && state.items.data.find(item => item.id === id);
            }
        };

        this.mutations = {
            SET_ITEMS(state, items) {
                state.items = items;
            },
            SET_ITEM(state, item) {
                state.item = item;
            }
        };

        this.actions = {
            add(_, data) {
                return serviceApi.create(data).then(r => {
                    Toast.success("Added successfully");
                    return r;
                });
            },

            fetchAll({ commit }, params = {}) {
                return serviceApi.getAll(params).then(r => {
                    commit("SET_ITEMS", r.data);
                });
            },

            fetchById({ commit }, id) {
                return serviceApi.getById(id).then(r => {
                    commit("SET_ITEM", r.data);
                });
            },

            update(_, data) {
                return serviceApi.update(data).then(r => {
                    Toast.success("The update was successful");
                    return r;
                });
            },

            delete(_, id) {
                return serviceApi.delete(id).then(r => {
                    Toast.success("Successfully deleted");
                    return r;
                });
            }
        };
    }
}
