<template>
    <div>
        <h1 class="pa-5">عروض للبيع</h1>

        <div class="sell-offers">
            <!-- start search form -->
            <v-form @submit.prevent="filterData">
                <v-row class="px-6">
                    <v-col cols="12" sm="6" lg="3">
                        <currencies-field v-model.trim="search.sell_currency" :label="$t('attributes.sell_currency')" clearable />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <currencies-field v-model.trim="search.buy_currency" :label="$t('attributes.buy_currency')" clearable />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <v-text-field v-model.trim="search.amount" :label="$t('attributes.amount')" clearable />
                    </v-col>
                    <v-col cols="12" sm="6" lg="auto" class="mb-2">
                        <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <!-- end search form -->

            <v-row v-if="loadingData && $store.state.app.loading">
                <v-col cols="12" md="4" v-for="n in 3" :key="n">
                    <v-skeleton-loader type="card, actions" />
                </v-col>
            </v-row>

            <h2 v-else-if="data.data && data.data.length == 0" class="pa-10 text-center">لم يتم العثور على نتائج</h2>

            <v-row v-else>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="i in data.data" :key="i.id">
                    <v-card class="mx-auto">
                        <v-row class="px-1">
                            <v-col cols="12 pb-0 px-5">
                                <v-card class="d-flex justify-center align-center offer-card-icon" dark>
                                    <bdi>{{ i.seller.currency.sign }}</bdi>
                                </v-card>
                            </v-col>
                            <v-col cols="12 pt-0">
                                <v-card-title class="pb-1">
                                    <bdi>{{ i.amount }}</bdi>
                                    {{ i.seller.currency.name }}
                                </v-card-title>
                                <v-card-text :title="i.created_at" class="pb-1">
                                    <v-icon small>mdi-clock-outline</v-icon>
                                    <bdi class="date-to-since ms-1" :data-value="i.created_at">{{ i.created_at }}</bdi>
                                </v-card-text>
                                <v-card-actions>
                                    <v-chip color="orange" class="ms-auto me-4" outlined>
                                        <bdi>{{ i.price }}</bdi>
                                        {{ i.receiver.currency.name }}
                                    </v-chip>
                                    <v-btn dark style="width:100px" @click="prepareToBuy(i)">شراء</v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <!-- <v-pagination
                        :color="$store.state.app.color"
                        class="my-4 mx-auto"
                        :total-visible="9"
                        :length="data.last_page"
                        :value="data.current_page"
                        @input="filterData"
                    ></v-pagination> -->
                    <pagination class="pagination-lg" :data="data" @page-changed="filterData" />
                </v-col>

                <Buy :item="itemAction" @delete-item="disapperItem" />
            </v-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Buy from "./Buy.vue";

export default {
    components: { Buy },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        this.search = Object.assign({}, routeTo.query);
        this.search.sell_currency = this.search.sell_currency && Number(this.search.sell_currency);
        this.search.buy_currency = this.search.buy_currency && Number(this.search.buy_currency);
        this.loadData().then(() => {
            next();
        });
    },

    data: function() {
        return {
            errors: {},
            search: Object.assign({}, this.$route.query),
            data: [],
            itemAction: {},
            interval: null,
            loadingData: false
        };
    },

    created() {
        this.$store.dispatch(`setting/fetch`);
        this.loadData();
    },

    mounted() {
        this.interval = setInterval(this.updateDateTimeToSince, 5000);
    },

    updated() {
        this.updateDateTimeToSince();
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    methods: {
        /**
         *  Set configuration for update item
         */
        prepareToBuy(item) {
            this.itemAction = {
                formBuyShow: true,
                fee: this.$store.state.setting.item.sell_offer_buy_fee,
                ...item
            };
        },

        updateDateTimeToSince() {
            const coll = document.getElementsByClassName("date-to-since");
            for (let i = 0; i < coll.length; i++) {
                coll[i].textContent = moment(coll[i].getAttribute("data-value")).fromNow();
            }
        },

        disapperItem(id) {
            this.data.data = this.data.data.filter(e => e.id !== id);
        },

        loadData() {
            this.$store.state.app.loading = true;
            this.loadingData = true;
            return axios
                .get("sell-offers", { params: this.search })
                .then(r => {
                    this.data = r.data.data;
                })
                .finally(() => {
                    this.$store.state.app.loading = false;
                    this.loadingData = false;
                });
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>

<style lang="scss">
.sell-offers {
    .offer-card-icon {
        width: 100%;
        height: 100%;
        min-height: 120px;
        font-size: 45px;
        @media (max-width: 599px) {
            min-height: 80px;
        }
    }
}
.pagination-lg button.v-pagination__item,
button.v-pagination__navigation {
    font-size: 1.15rem;
    height: 42px;
    min-width: 42px;
}
</style>
