<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('transfers.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("transfers.title") }}</div>
                </div>

                <!-- btn print item -->
                <!-- <v-btn
                    v-if="_hasPermissions('transfers.print_open')"
                    fab
                    small
                    color="blue-grey darken-3"
                    dark
                    class="ms-1"
                    :href="getPrintLink()"
                    target="_blank"
                >
                    <v-icon>mdi-printer</v-icon>
                </v-btn> -->
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selectedHeaders" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3" class="pb-0">
                                <currencies-field v-model.trim="search.currency" clearable />
                            </v-col>

                            <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                <component
                                    v-if="typeof field === 'object'"
                                    :is="field.component"
                                    v-model.trim="search[field.name]"
                                    :label="field.label"
                                    v-bind="field.props"
                                />
                                <v-text-field v-else v-model.trim="search[field]" :label="$t(`attributes.${field}`)" maxlength="32" clearable />
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="selectedHeaders"
                    :items="data.data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <!--  <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small color="info" class="me-1" fab :to="`/transfers/${item.id}`">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                        </div>
                    </template> -->
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.data || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("transfer/fetchAll", { page, ...params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            errors: {},
            search: Object.assign({}, this.$route.query),
            searchFields: [
                "id",
                "from_account",
                "to_account",
                "amount",
                "fee",
                "net_amount",
                "note",
                { component: "date-field", name: "date_from", label: this.$t("search.date_from"), props: { clearable: true } },
                { component: "date-field", name: "date_to", label: this.$t("search.date_to"), props: { clearable: true } }
            ],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.to"), value: "to.client.name" },
                { text: this.$t("attributes.from_account"), value: "from_account" },
                { text: this.$t("attributes.to_account"), value: "to_account" },
                { text: this.$t("attributes.currency"), value: "from.currency.name" },
                { text: this.$t("attributes.amount"), value: "amount" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.net_amount"), value: "net_amount" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" }
                //{ text: this.$t("attributes.actions"), value: "actions" }
            ],
            selectedHeaders: []
        };
    },

    computed: {
        ...mapState({
            data: state => state.transfer.items
        })
    },

    created() {
        this.selectedHeaders = this.headers.filter(i => !["from_account", "to_account", "note", "created_at"].includes(i.value));
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        rowClass(item) {
            return null;
            //return (!item.activated_at && "green white--text") || (!item.active && "red white--text");
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        },

        getPrintLink() {
            let p = new URLSearchParams({
                key: this.$store.state.auth.key,
                ...this.search
            }).toString();
            return serverUrl(`/transfers/open?${p}`).toString();
        }
    }
};
</script>
